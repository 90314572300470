<template>
    <div class="card-container" @click="onclickHandel">
        <div class="card-title">
            <span class="line"></span>
            {{ title }}
            <img v-if="icon === 'type1'" class="card-icon" src="../images/type1.png" />
            <img v-if="icon === 'type4'" class="card-icon" src="../images/type4.png" />
            <img v-if="icon === 'type2'" class="card-icon" src="../images/type2.png" />
            <img v-if="icon === 'type8'" class="card-icon" src="../images/type8.png" />
            <img v-if="icon === 'type5'" class="card-icon" src="../images/type5.png" />
            <img v-if="icon === 'type6'" class="card-icon" src="../images/type6.png" />
            <img v-if="icon === 'type7'" class="card-icon" src="../images/type7.png" />
        </div>
        <div class="card-content">
            <span class="card-value">
                {{ value }}
            </span>
            <span v-if="percentShow" class="card-percent-value">
                {{ percent.toFixed(2) }}%
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            default: '电池总量'
        },
        value: {
            type: Number,
            default: 1000
        },
        percent: {
            type: Number,
            default: 2
        },
        percentShow: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: 'type1'
        }
    },
    methods: {
        onclickHandel() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="less">
    .card-container {
        box-sizing: border-box;
        flex: 1;
        height: 100%;
        border: 1px solid #d2d2d2;
        margin: 0 40px;
        box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        .card-title {
            position: relative;
            display: flex;
            align-items: center;
            height: 50px;
            padding-left: 20px;
            color: #333;
            font-size: 16px;
            font-family: PingFang Regular!important;;
            .line {
                display: inline-block;
                width: 10px;
                height: 3px;
                background: #0096ff;
                margin-right: 10px;
            }
            .card-icon {
                position: absolute;
                right: 24px;
                width: 18px;
                height: 16px;
            }
        }
        .card-content {
            display: flex;
            height: calc(100% - 50px);
            width: 100%;
            align-items: center;
            .card-value, .card-percent-value {
                flex: 1;
                display: block;
                padding-left: 45px;
                font-size: 30px;
                color: #333;
                font-family: PingFang Regular!important;
            }
        }
    }
</style>