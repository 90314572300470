<template>
  <div class="work-bench-container">
    <div class="items">
      <div class="work-top" v-for="(item, index) in pageDatas.deviceDetailList" :key="index" @click="gotoCloud(item)">
        <div class="item-title" :style="item.type === 2?'color: #D9001B':''">{{item.type === 1 ? '设备总量':(item.type === 2 ? '故障报警设备' : (item.type === 3 ? '离线设备' : (item.type === 4 ? '低电量设备' : (item.type === 5 ? '回调设备' : '运维设备'))))}}</div>
        <div class="item-data">{{item.totalAmount || 0}}</div>
        <div class="data-content">
          <div class="data-left">
            <div class="data-title">{{item.type === 1 ? '运行' : '已处理'}}</div>
            <div class="data-text">{{item.goodAmount || 0}}</div>
          </div>
          <div class="data-right">
            <div class="data-title">{{item.type === 1 ? '未运行' : '未处理'}}</div>
            <div class="data-text">{{item.badAmount || 0}}</div>
          </div>
        </div>
        <div :ref="'myCharts'+item.type" :id="'myCharts'+item.type">

        </div>
      </div>
    </div>
    <div class="bottom-data">
      <div class="bottom-left">
        <div class="left-title">
          <div class="title-content">消息中心</div>
          <div class="title-more" @click="gotoMsgHandle">查看更多>></div>
        </div>
        <div class="bottom-left-items" v-for="(item, index) in pageDatas.msgList" :key="index">
          <div class="msg-datas">
            <div class="msg-type">{{msgTypeStr(item.msgType)}}</div>
            <div class="msg-time">{{item.interval || '-'}}</div>
            <div class="msg-sn">{{item.sn || '-'}}</div>
          </div>
          <div class="msg-info">{{item.info || '-'}}</div>
        </div>
      </div>

      <div class="bottom-right">
        <div class="right-title">
          <div class="title-content">未处理工单</div>
          <div class="title-more" @click="gotoOrderHandle">查看更多>></div>
        </div>
        <div class="bottom-left-items" v-for="(item, index) in pageDatas.workOrderBriefList" :key="index">
          <div class="msg-datas">
            <div class="msg-type">{{orderTypeStr(item.orderType)}}</div>
            <div class="msg-time">{{item.interval || '-'}}</div>
            <div class="msg-sn">{{item.sn || '-'}}</div>
          </div>
          <div class="msg-info">{{item.info || '-'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./Index.less";
import Card from "./components/Card";
import axios from "@/utils/axios";
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
export default {
  name: "WorkBench",
  components: {
    Card,
  },
  data() {
    return {
      overviewData: {
        numOfAlarm: 0, // 告警总量
        numOfBattery: 0, // 电池总量
        numOfOffline: 0, // 离线总量
        numOfPreAlarm: 0, // 预警总量
        ratioOfAlarm: 0, // 告警占比
        ratioOfOffline: 0, // 离线占比
        ratioOfPreAlarm: 0, // 预警占比
      },
      maintenanceOverviewData: {
        numOfNew: 0,
        numOfOperator: 0,
        numOfSite: 0,
        numOfTeam: 0,
      },
      orderOverViewData: {
        numOfFinishedInspectJob: 0,
        numOfFinishedRescueJob: 0,
        numOfInspectJob: 0,
        numOfRescueJob: 0,
        numOfUnassignedInspectJob: 0,
        numOfUnassignedRescueJob: 0,
        numOfUnauditedInspectJob: 0,
        numOfUnauditedRescueJob: 0,
        numOfAssignedInspectJob: 0,
        numOfAssignedRescueJob: 0,
      },
      totalList: {
        aa: 0,
        bb: 0,
        total: 1900,
      },
      formData: {
        organId: null,
        userId: null,
      },
      pageDatas: {}
    };
  },
  methods: {
    getWorkData() {
      axios.post(`/api/workbench/query`,this.formData).then(res => {
        this.pageDatas = res
        let deviceDetailList = res.deviceDetailList || []
        if(deviceDetailList.length > 0) {
          deviceDetailList.forEach(item => {
            this.$nextTick(() => {
              // this.drawCharts(item)
            })
          })
        }
      })
      .catch((err) => {
          this.$message.error(err);
        });
    },
    gotoMsgHandle() {
      this.$router.push({
        name: "CloudControl",
      });
    },
    gotoOrderHandle() {
      this.$router.push({
        name: "MineJob",
      });
    },
    msgTypeStr(value) {
      if(value) {
        if(value === 1) {
          return "告警消息"
        } else if (value === 2) {
          return "工单消息"
        } else if (value === 3) {
          return "公告消息"
        } else if (value === 4) {
          return "运维消息"
        } else {
          return "-"
        }
      } else {
        return "-"
      }
    },
    orderTypeStr(value) {
      if(value) {
        if(value === 1) {
          return "运维工单"
        } else if (value === 2) {
          return "客服工单"
        } else if (value === 3) {
          return "回调工单"
        } else if (value === 4) {
          return "售后工单"
        } else {
          return "-"
        }
      } else {
        return "-"
      }
    },
    drawCharts(item) {
      let ss = document.getElementById('myChart'+item.type)
      debugger
      let myCharts = echarts.init(document.getElementById('myChart'+item.type));
      debugger
      let option = {}
      option = {
        title: {
          text: 'Referer of a Website',
          subtext: 'Fake Data',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: [
              { value: item.goodAmount/item.totalAmount||1, name: item.type===1?'运行':'已处理' },
              { value: item.badAmount/item.totalAmount||1, name: item.type===1?'未运行':'未处理' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myCharts.setOption(option)
    },
    getOverviewData() {
      axios
        .get("/api/console/overview")
        .then((rs) => {
          const {
            numOfAlarm,
            numOfBattery,
            numOfOffline,
            numOfPreAlarm,
            ratioOfAlarm,
            ratioOfOffline,
            ratioOfPreAlarm,
          } = rs;
          this.overviewData = {
            numOfAlarm,
            numOfBattery,
            numOfOffline,
            numOfPreAlarm,
            ratioOfAlarm,
            ratioOfOffline,
            ratioOfPreAlarm,
          };
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    gotoCloud(row) {
      if(row.type === 4) {
        this.$router.push({
          name: "CloudControl",
          params: {
            sn: row.sn,
            type: 2,
            activeName: 'third'
          },
        });
      } else if (row.type === 3) {
        this.$router.push({
          name: "CloudControl",
          params: {
            sn: row.sn,
            type: 3,
            activeName: 'second'
          },
        });
      }
    },
    getMaintenanceOverviewData() {
      axios
        .get("/api/console/overviewOfMaintenance")
        .then((rs) => {
          this.maintenanceOverviewData = r;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    getOrderOverviewData() {
      axios
        .get("/api/console/overviewOfJob")
        .then((rs) => {
          this.orderOverViewData = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    jumpBatteryManagePage() {
      this.$router.push("/battery-management");
    },
    jumpHistoryAlarm(type) {
      this.$router.push(`/history-alarm/${type}`);
    },
    jumpFaultOrder(status) {
      this.$router.push(`/fault-rescue-management/${status}`);
    },
    jumpPlannedOrder(status) {
      this.$router.push(`/planned-overhaul/${status}`);
    },
    jumpSitePage() {
      this.$router.push(`/site-management`);
    },
    jumpTeam() {
      this.$router.push(`/maintenance-team-management`);
    },
    jumpOperater() {
      this.$router.push(`/maintenance-person-management`);
    },
    jumpOfflinePage() {
      this.$router.push("/offline-info");
    },
    drawTotalCharts() {
      let totalCharts = echarts.init(this.$refs.totalCharts);
      let { totalList } = this;
      if (totalList) {
        // 绘制图表
        totalCharts.setOption({
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              name: "设备总量",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: (500 / totalList.total).toFixed(2),
                  name: "运行",
                },
                { value: (1400 / totalList.total).toFixed(2), name: "未运行" },
              ],
            },
          ],
        });
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.totalCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 500px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
    },
		drawAlarmCharts() {
			let alarmCharts = echarts.init(this.$refs.alarmCharts);
      let { totalList } = this;
      if (totalList) {
        // 绘制图表
        alarmCharts.setOption({
          tooltip: {
            trigger: "item",
          },
          series: [
            {
              name: "设备总量",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: [
                {
                  value: (900 / totalList.total).toFixed(2),
                  name: "运行",
                },
                { value: (1000 / totalList.total).toFixed(2), name: "未运行" },
              ],
            },
          ],
        });
      } else {
        this.$nextTick(() => {
          const dom = this.$refs.alarmCharts;
          dom.innerHTML =
            '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 500px">暂无数据</div>';
          dom.removeAttribute("_echarts_instance_");
        });
      }
		}
  },
  mounted() {
    this.formData.organId = localStorage.getItem("organId");
    this.formData.userId = localStorage.getItem("userId");
    this.getWorkData()
    // this.getOverviewData();
    // this.getMaintenanceOverviewData();
    // this.getOrderOverviewData();
    // this.drawTotalCharts();
		// this.drawAlarmCharts()

  },
};
</script>
